import Section from "./Section";
import Profile from "./Profile";
import {Card, Grid} from "antd";

const {useBreakpoint} = Grid;

const CV = ({personalData, sections}) => {

    const makeSections = (section) => {
        return(<Section {...section}/>)
    }

    const screens = useBreakpoint()

    return(
        <div style={{padding:screens['md'] ? '24px' : '12px', backgroundColor:'#f0f2f5'}}>
            <Card style={{maxWidth:'960px', margin:'auto', borderRadius:'6px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'}}
            bodyStyle={{padding:screens['md'] ? '24px' : '12px'}}>
                <Profile {...personalData}/>
                {sections.map(makeSections)}
            </Card>
        </div>

    )
}

export default CV
