import './App.css';
import CV from "./CV";
import 'antd/dist/antd.css'
import Icon, {
    BankOutlined,
    CodeOutlined,
    ExperimentOutlined,
    ProfileOutlined,
    RocketOutlined,
    SmileOutlined, StarOutlined,
    TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import { Rate } from "antd";
import BlueskyLogo from './BlueskyLogo';

function App() {
    return (
        <CV
            personalData={{
                name: 'Claire V. Hammond',
                title: [
                    'Senior Research Engineer at ORGO Inc.',
                    'PhD, Mechanical Engineering',
                ],
                image: '/headshot128hd.jpg',
                contacts: [
                    { type: 'email', value: 'me@cvhammond.com' },
                    // { type: 'phone', value: '+00 (123) 456 78 90' },
                    { type: 'location', value: 'Sapporo, Japan' },
                    { type: 'linkedin', value: 'cvhammond' },
                    { type: 'bluesky', value: 'cvhammond.com', logo: <span style={{marginRight:'8px'}}><BlueskyLogo/></span> },
                    { type: 'github', value: 'cvhammond' },
                    // {type: 'reddit', value: 'CVHammond'}
                ]
            }}
            sections={[
                {
                    type: 'text',
                    title: 'Profile',
                    // content: 'The way we move informs our pain and our dysfunction. My work aims to contribute to the quantification of the human body in motion and the use of that information to improve treatment outcomes.',
                    content: 'I develop tools for musculoskeletal modeling and simulation to facilitate researchers and improve clinical outcomes. My Phd work focused on the development of a pipeline for personalizing musculoskeletal models to individual patients. I have experience in software development, biomechanics, and clinical research.',
                    icon: <UserOutlined />
                },
                {
                    type: 'common-list',
                    title: 'Experience',
                    // description: 'Optional',
                    icon: <ProfileOutlined />,
                    items: [
                        {
                            title: 'Senior Research Engineer',
                            authority: 'ORGO Inc.',
                            description: 'Developing motion capture technologies for clinical and research applications',
                            authorityWebSite: 'https://orgo.co.jp',
                            authorityMeta: 'Research & Development',
                            rightSide: '2024 - Present',
                        },
                        {
                            title: 'Clinical Biomechanist',
                            authority: 'Stanford Children\'s Health',
                            description: 'Leading data collection and analysis of children and adults with altered gait',
                            authorityWebSite: 'https://stanfordchildrens.org',
                            authorityMeta: 'Clinical Gait Lab',
                            rightSide: '2023 - 2024',
                        },
                        // {
                        //     title: 'Developer & Technology Lead',
                        //     authority: 'Roxie Health',
                        //     description: 'Developing the future of digital care assessments for aging adults',
                        //     authorityWebSite: 'https://roxiehealth.com',
                        //     authorityMeta: 'Mass Challenge Startup',
                        //     rightSide: 'Jul. 2022 - Present',
                        // },
                        {
                            title: 'Graduate Student',
                            authority: 'Rice Computational Neuromechanics Lab',
                            description: 'Leading development of an NIH funded project for the next generation of OpenSim tools',
                            authorityWebSite: 'https://rcnl.rice.com',
                            authorityMeta: 'NIH R01 EB030520',
                            rightSide: '2021 - 2024',
                            // descriptionTags: ['OpenSim', 'MATLAB']
                        },
                        {
                            title: 'Biomechanist/Tech Lead',
                            authority: 'Movement Health Science Inc',
                            description: 'Developing data-driven technology to improve human performance',
                            authorityWebSite: 'https://movementhealthscience.com',
                            authorityMeta: 'Funded Startup',
                            rightSide: '2020 - 2021'
                        },
                    ]
                },
                {
                    type: 'common-list',
                    title: 'Education',
                    icon: <BankOutlined />,
                    items: [
                        {
                            title: 'Mechanical Engineering (PhD)',
                            authority: 'Rice University',
                            authorityMeta: 'Rice Computational Neuromechanics Lab',
                            authorityWebSite: 'https://www.rice.edu',
                            rightSide: '2021 - 2024'
                        },
                        {
                            title: 'Computer Science (MS)',
                            authority: 'Georgia Institute of Technology',
                            authorityMeta: 'Computational Perception & Robotics',
                            authorityWebSite: 'https://www.gatech.edu',
                            rightSide: '2017-2019'
                        },
                        {
                            title: 'Mechanical Engineering (Honors BEng)',
                            authority: 'University of Delaware',
                            authorityMeta: 'Minor in Computer Science',
                            authorityWebSite: 'https://www.udel.edu',
                            rightSide: '2013-2017'
                        }
                    ]
                },
                {
                    type: 'common-list',
                    title: 'Conference Presentations',
                    icon: <TeamOutlined />,
                    items: [
                        {
                            title: 'Stroke Grand Challenge Competition and NMSM Pipeline Training Workshop',
                            authority: 'American Society of Biomechanics',
                            authorityWebSite: 'https://asbweb.org/asb-2023/',
                            authorityMeta: 'Knoxville, TN, USA',
                            rightSide: 'Aug. 2023'
                        },
                        {
                            title: 'Easy-to-use MATLAB Software for Personalizing OpenSim Neuromusculoskeletal Models',
                            authority: 'International Society of Biomechanics / Japanese Society of Biomechanics',
                            authorityWebSite: 'https://www.isb-jsb2023.com/',
                            authorityMeta: 'Fukuoka, Japan',
                            rightSide: 'Jul. 2023'
                        },
                        {
                            title: 'Personalization of Joint Parameters Through Optimization Decreases Inverse Kinematics Marker Distance Errors',
                            authority: 'World Congress of Biomechanics',
                            authorityWebSite: 'https://www.wcb2022.com/',
                            authorityMeta: 'Taipei, Taiwan',
                            rightSide: 'Jul. 2022'
                        },
                        {
                            title: 'Peer Reviewer',
                            authority: 'Journal of Open Source Software',
                            // description: ,
                            authorityWebSite: 'https://joss.theoj.org/',
                            // authorityMeta: '',
                        },
                    ]
                },
                {
                    type: 'common-list',
                    title: 'Projects',
                    icon: <ExperimentOutlined />,
                    items: [
                        {
                            title: 'Neuromusculoskeletal Modeling (NMSM) Pipeline',
                            authority: 'Rice Computational Neuromechanics Lab',
                            description: 'First author (publ. pending) of pipeline to facilitate neuromusculoskeletal modeling research',
                            authorityWebSite: 'https://nmsm.rice.edu/',
                            rightSide: 'Aug. 2023'
                        },
                        {
                            title: 'Biomechanics Zone',
                            authority: 'Self-Directed',
                            description: 'Developed a website for biomechanics education and research',
                            authorityWebSite: 'https://biomechanics.zone/',
                            rightSide: 'Aug. 2021'
                        },
                    ]
                },
                {
                    type: 'tag-list',
                    title: 'Skills',
                    icon: <RocketOutlined />,
                    items: [
                        'OpenSim',
                        // 'MATLAB',
                        // 'React',
                        // 'Python',
                        'Multi-body Dynamics',
                        'Software Engineering',
                        'Simulation & Modeling',
                        'Computer Vision',
                    ]
                },
                {
                    type: 'common-list',
                    title: 'Languages',
                    icon: <CodeOutlined />,
                    items: [
                        {
                            title: 'MATLAB',
                            authority: 'Professional',
                            rightSide: <Rate value={5} />
                        },
                        {
                            title: 'Rust',
                            authority: 'Strong',
                            rightSide: <Rate value={4} />
                        },
                        {
                            title: 'Python',
                            authority: 'Proficient',
                            rightSide: <Rate value={3} />
                        },
                    ]
                },
                {
                    type: 'tag-list',
                    title: 'Hobbies',
                    icon: <SmileOutlined />,
                    items: [
                        'Gravel Cycling',
                        'Coffee',
                        'My Cat',
                    ]
                },
            ]}
        />
    )
}

export default App;
