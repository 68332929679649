import {Divider, Space, Tag, Typography, Grid} from "antd";

const {Title, Text, Link} = Typography;
const {useBreakpoint} = Grid

const iconProps = {style: {fontSize: '24px'}}

const Section = ({type, icon, title, content, items, groups}) => {

    const screens = useBreakpoint()

    return (
        <div style={{padding:screens['md'] ? '0 24px' : '0 12px'}}>
            <Space>
                {icon.type ? icon.type.render({...iconProps}) : <></>}
                <Title style={{marginTop: '8px'}} level={3}>{title}</Title>
            </Space>
            <Divider style={{marginTop: '-4px', marginBottom: '16px'}}/>
            <div style={{margin: '16px', fontSize: '16px'}}>
                <SectionBody type={type} content={content} items={items}
                             groups={groups}/>
            </div>
        </div>
    )
}

export default Section

const SectionBody = ({style, type, content, items, groups}) => {
    switch (type) {
        case 'text':
            return <div>{content}</div>
        case 'common-list':
            return <CommonList items={items}/>
        case 'projects-list':
            return <ProjectsList groups={groups}/>
        case 'tag-list':
            return <TagList items={items}/>
        default:
            return <></>
    }
}

const CommonList = ({items}) => {

    const screens = useBreakpoint()


    return (
        <div>
            {items.map((item) => {
                return (<div style={{marginBottom:'18px'}}>
                        <div style={{display: screens['md'] ? 'flex':'block', justifyContent: screens['md'] ? 'none': 'space-between'}}>
                        {/*<div>*/}
                            <Title style={{margin:0, maxWidth:'85%'}} level={4}>{item.title}</Title>
                            {screens['md'] ? <span style={{textAlign: 'right'}}>{item.rightSide}</span> : <div style={{textAlign: 'left'}}>{item.rightSide}</div>}
                        </div>
                        <div>
                            <Space wrap>
                                <Link href={item.authorityWebSite ?? ''}>
                                    <Title style={{margin:0}} level={5}>{item.authority ?? <></>}</Title>
                                </Link>
                                <Text type={'secondary'}>{item.authorityMeta ?? <></>}</Text>
                            </Space>
                            <div>{item.description}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const ProjectsList = ({groups}) => {
    return (
        <div>
            {groups.map((group) => {
                return(
                    <>
                        <div style={{marginBottom:'12px'}}>
                        <Title style={{margin:0}} level={4}>{group.sectionHeader}</Title>
                        {group.description && <div>{group.description}</div>}
                        </div>
                        {group?.items?.map && group.items.map((item) => { return(<div style={{marginBottom:'12px', marginLeft:'12px'}}>
                            <Link href={item.projectUrl}><Title style={{margin:0}} level={5}>{item.title}</Title></Link>
                                {item.description && <div>{item.description}</div>}
                                </div>
                        )
                        })}
                    </>
                )
            })}
        </div>
    )
}

const TagList = ({items}) => {
    return (
        <>
            {items.map((item) => {
                return (
                    <Tag style={{fontSize: '16px'}} color={'blue'}>{item}</Tag>
                )
            })}
        </>
    )
}
