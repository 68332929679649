import { Card, Avatar, Typography, List, Row, Col, Space, Tag, Button } from "antd";
import {
    EnvironmentOutlined,
    GithubOutlined, GlobalOutlined,
    LinkedinOutlined,
    MailOutlined,
    PhoneOutlined,
    RedditOutlined, TwitterOutlined, UserOutlined
} from "@ant-design/icons";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import BlueskyLogo from "../BlueskyLogo";

const { Meta, Grid } = Card;
const { Link, Title, Text } = Typography;

const typeToIcon = {
    email: <MailOutlined />,
    phone: <PhoneOutlined />,
    location: <EnvironmentOutlined />,
    website: <GlobalOutlined />,
    linkedin: <LinkedinOutlined />,
    twitter: <TwitterOutlined />,
    github: <GithubOutlined />,
    reddit: <RedditOutlined />,
    bluesky: <BlueskyLogo/>,
}

const typeToColor = {
    email: "#0072C6",
    phone: "#075e54",
    location: "#DD4B3E",
    website: "#7cb9cb",
    linkedin: "#0077B5",
    twitter: "#55acee",
    github: '#171516',
    reddit: "#FF5700",
    bluesky: "#1185FE",
}

export const getFixedUrl = (url) => (
    /^(http|https|ftp)/.test(url) ? url : `https://${url}`
);

const makeLink = ({ type, value }) => {
    switch (type) {
        case 'email':
            return 'mailto:' + value
        case 'phone':
            return 'tel:' + value
        case 'location':
            return `https://maps.google.com/?q=${encodeURIComponent(value)}`
        case 'website':
            return getFixedUrl(value)
        case 'github':
            return 'https://github.com/' + value
        case 'twitter':
            return 'https://twitter.com/' + value
        case 'reddit':
            return 'https://reddit.com/u/' + value
        case 'linkedin':
            return 'https://linkedin.com/in/' + value
        case 'bluesky':
            return 'https://bsky.app/profile/' + value
    }
}


const Profile = ({ image, name, title, contacts }) => {

    const screens = useBreakpoint()

    const avatarRender =
        <Col style={{ marginBottom: '28px' }} span={screens['md'] ? 16 : 24}>
            <Space style={{ display: screens['md'] ? 'inline-flex' : 'flex', justifyContent: 'center', position: 'relative', top: '16px' }} align={'center'}>
                <Avatar style={{ marginRight: '8px' }} size={128} shape={'circle'} src={image} />
                <span>
                    <Title style={{ marginBottom: '0' }} level={screens['xs'] ? 3 : 2}>{name}</Title>
                    {title.map && title.map(line => (
                        <div><Text style={{ fontSize: '16px' }} type={'secondary'}>{line}</Text></div>
                    ))}
                    {!title.map && <Text style={{ fontSize: '16px' }} type={'secondary'}>{title}</Text>}
                </span>
            </Space>
        </Col>


    const contactsRender =
        <Col span={screens['md'] ? 8 : 24}>
            <List
                itemLayout={'vertical'}
                dataSource={contacts}
                split={false}
                renderItem={item => (
                    <List.Item style={{ display: screens['md'] ? 'block' : 'flex', justifyContent: 'center' }}>
                        <Button className={'contact-btn'}
                            style={{ backgroundColor: item.type ? typeToColor[item.type] : undefined }}
                            ghost
                            shape={'round'}
                            target={'_blank'} href={makeLink(item)}
                            size={'small'}
                            icon={item.logo ? item.logo : item.type ? typeToIcon[item.type] :
                                <UserOutlined />}
                        >
                            {item.value}
                        </Button>
                    </List.Item>
                )}
            />
        </Col>

    return (
        <Card style={{ marginBottom: '24px', borderRadius: '6px', backgroundColor: '#D5DED9' }}>
            <Row wrap>
                {avatarRender}
                {contactsRender}
            </Row>
        </Card>
    )
}

export default Profile
